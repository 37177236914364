<template>
  <div
    v-if="mappedNavigation.length > 1"
    class="breadcrumbs"
    :class="{
      'breadcrumbs--themed': !!themeColor,
      'breadcrumbs--transparent': isTransparent,
      'breadcrumbs--dark-font': isDarkFont,
    }"
  >
    <ul class="breadcrumbs__list">
      <li class="breadcrumbs__item breadcrumbs__item--home">
        <T3Link
          :to="homePageBreadcrumb.link"
          :title="null"
        >
          {{ homePageBreadcrumb.title }}
        </T3Link>
      </li>

      <li
        v-if="hiddenBreadcrumbs.length"
        v-click-outside="closeBreadcrumbsHiddenList"
        class="breadcrumbs__item breadcrumbs__item--hidden"
      >
        <input
          id="breadcrumbsHiddenListToggle"
          v-model="isBreadcrumbsHiddenListActive"
          type="checkbox"
        />

        <label
          for="breadcrumbsHiddenListToggle"
          class="breadcrumbs__hidden-list-toggle"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M4 9.333c-.367 0-.68-.13-.942-.392a1.284 1.284 0 01-.391-.942c0-.366.13-.68.391-.941.262-.261.575-.392.942-.392s.68.13.942.392c.26.26.391.575.391.941 0 .367-.13.68-.391.942A1.284 1.284 0 014 9.333zm4 0c-.367 0-.68-.13-.942-.392a1.284 1.284 0 01-.391-.942c0-.366.13-.68.391-.941.262-.261.575-.392.942-.392s.68.13.942.392c.26.26.391.575.391.941 0 .367-.13.68-.391.942A1.284 1.284 0 018 9.333zm4 0c-.367 0-.68-.13-.942-.392a1.284 1.284 0 01-.391-.942c0-.366.13-.68.391-.941.261-.261.575-.392.942-.392s.68.13.942.392c.26.26.391.575.391.941 0 .367-.13.68-.391.942a1.284 1.284 0 01-.942.392z"
              fill="currentColor"
            />
          </svg>
        </label>

        <ul class="breadcrumbs__hidden-list">
          <li
            v-for="link in hiddenBreadcrumbs"
            :key="link.title"
            class="breadcrumbs__item"
          >
            <component
              :is="link.spacer ? 'span' : 't3-link'"
              :title="null"
              :to="link"
            >
              {{ link.title }}
            </component>
          </li>
        </ul>
      </li>

      <li
        v-if="currentPageBreadcrumb"
        class="breadcrumbs__item breadcrumbs__item--current"
      >
        <span :to="currentPageBreadcrumb.link">
          {{ currentPageBreadcrumb.title }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { withBase } from 'ufo'
import { computed, ref } from 'vue'

import { defineBreadcrumb, useSchemaOrg } from '#imports'

import type { BaseBreadcrumbsProps } from '~ui/types'

const props = defineProps<BaseBreadcrumbsProps>()

const mappedNavigation = computed(() => {
  return props.navigation.map((link, index) => ({
    ...link,
    ...index === 0 ? { title: 'Home' } : {}
  }))
})

const homePageBreadcrumb = computed(() => {
  return mappedNavigation.value?.[0]
})

const currentPageBreadcrumb = computed(() => {
  return mappedNavigation.value?.[mappedNavigation.value.length - 1]
})

const hiddenBreadcrumbs = computed(() => {
  return mappedNavigation.value?.slice(1, -1)
})

const isBreadcrumbsHiddenListActive = ref<boolean>(false)
const closeBreadcrumbsHiddenList = () =>
  isBreadcrumbsHiddenListActive.value = false

const breadcrumbsSchema = computed(() => {
  if (mappedNavigation.value.length <= 1) return null

  return mappedNavigation.value
    .filter((link) => !link?.spacer)
    .map((link) => ({
      name: link.title,
      item: withBase(link.link, props.baseUrl)
    }))
    .slice(1)
})

useSchemaOrg([
  breadcrumbsSchema.value
    ? defineBreadcrumb({ itemListElement: () => breadcrumbsSchema.value })
    : null
])
</script>

<style lang="scss">
.breadcrumbs {
  $breadcrumb-gap: 24px;

  position: relative;
  z-index: z-index(header) - 1;
  background-color: color(grey-100);
  border-bottom: 1px solid color(grey-250);
  padding-block: rem(12px);
  @include frame-content-container(large);

  :where(& ul) {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__list {
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  &__item {
    color: color(grey-550);
    white-space: nowrap;

    &::before {
      content: '/';
      display: inline-block;
      width: $breadcrumb-gap;
      color: color(grey-350);
      font-weight: 400;
      text-align: center;
    }

    &:first-child::before {
      content: none;
    }

    &--hidden {
      position: relative;
      display: flex;
      align-items: center;
    }

    &--current {
      font-weight: 500;
      color: color(grey-650);
    }
  }

  #breadcrumbsHiddenListToggle {
    @include visually-hidden();
  }

  &__hidden-list-toggle {
    display: grid;
    place-items: center;
    height: $breadcrumb-gap;
    aspect-ratio: 1;
    background-color: color(grey-200);
    color: color(grey-650);
    border-radius: em(4px);
    text-align: center;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;

    & svg {
      height: calc($breadcrumb-gap * (2 / 3));
      aspect-ratio: 1;
    }

    @include media-query(sm) {
      display: none;
    }
  }

  &__hidden-list {
    display: flex;
  }

  // themed
  &--themed {
    background-color: color(dark-blue-950);
    border-color: color(dark-blue-900);
  }

  &--themed &__hidden-list-toggle {
    background-color: rgba(color(grey-200), 0.1);
    color: rgba(color(grey-100), 0.8);
  }

  &--themed &__item {
    color: color(grey-300);

    &--current {
      color: color(grey-200);
    }
  }

  // transparent
  &--transparent {
    position: absolute;
    top: var(--content-offset, 0px);
    width: 100%;
    background-color: transparent;
    border-bottom: none;
  }

  &--transparent &__hidden-list-toggle {
    background-color: rgba(color(grey-200), 0.1);
    color: rgba(color(grey-100), 0.8);
  }

  &--transparent &__item {
    color: color(grey-200);

    &::before {
      color: color(grey-250);
    }

    &--current {
      color: color(white);
    }

    & > a:is(:hover, :active, :focus) {
      color: color(white);
    }
  }

  // dark
  &--dark-font &__item {
    color: color(grey-1000);

    &::before {
      color: color(grey-1000);
    }

    &--current {
      color: color(grey-1000);
    }
  }

  // hidden
  @include media-query(max sm) {
    &__hidden-list {
      flex-direction: column;
      position: absolute;
      top: calc(100% + calc($breadcrumb-gap / 6));
      left: calc(100% - $breadcrumb-gap);
      max-width: calc(
        100vw - 4.7ch - $breadcrumb-gap - rem($container-padding) * 2
      );
      overflow: hidden;
      background-color: color(white);
      border: 1px solid color(grey-250);
      border-radius: em(4px);
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
      transition:
        opacity 0.2s,
        visibility 0.2s;

      #breadcrumbsHiddenListToggle:checked ~ & {
        pointer-events: all;
        opacity: 1;
        visibility: visible;
      }
    }

    &__hidden-list &__item {
      color: color(grey-550);
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        content: none;
      }

      &--current {
        color: color(grey-550);
      }

      & + * {
        border-top: 1px solid color(grey-250);
      }

      & > * {
        display: inline-block;
        width: 100%;
        padding: rem(10px);
      }

      & > a:is(:hover, :active, :focus) {
        color: color(primary);
      }
    }
  }
}
</style>
